import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";
import jaLocale from "date-fns/locale/ja";
import React, { useEffect, useMemo, useState } from "react";
import { Badge, Button, Card, Table } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import * as XLSX from "xlsx";
import {
  DeleteUserService,
  getCategoryService,
  getSentNotification,
  getSentNotificationList,
  getUserUpdateService,
  sendNotificationPerson,
} from "../../../services/ShinryuService";
import {
  cateList,
  getLocalStorage,
  notiList,
  userDetail,
  userIdState,
} from "../../../store/recoil";
import PageTitle from "../../layouts/PageTitle";
const SentNoti = () => {
  class ExtendedUtils extends DateFnsUtils {
    getCalendarHeaderText(date) {
      return format(date, "yyyy MMM", { locale: this.locale });
    }
    getDatePickerHeaderText(date) {
      return format(date, "MMMd日", { locale: this.locale });
    }
  }
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // const id = searchParams.get("id");
  const [id, setId] = useState(searchParams.get("id"));
  const [key, setKey] = useState(searchParams.get("key"));
  const [activeToggle, setActiveToggle] = useState("setting");
  const [content, setuserDetail] = useRecoilState(userDetail);
  const [admininfo, setadminDetail] = useState("");
  const [userGroupList, setUserGroupList] = useState([
    "交通(工事)",
    "交通(施設)",
    "施設",
  ]);
  const history = useHistory();
  const [query, setQuery] = useState("");
  const [notilist, setNotiList] = useRecoilState(notiList);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterLists, setFilterLists] = useState({
    cat1: [],
    cat2: [],
  });
  const [catlist, setCateList] = useRecoilState(cateList);
  const [indefiniteEnd, setIndefiniteEnd] = useState(false);
  const [selectedBigClassName, setSelectedBigClassName] = useState("");
  const [tags, setTags] = useState([[], [], [], [], [], [], [], [], [], []]);
  const [formValues, setFormValues] = useState({
    userEmail: content.userEmail,
    userUniqueId: content.userUniqueId,
    userPassword: content.userPassword,
    userNumber: content.userNumber,
    userName: content.userName,
    userNameHurigana: content.userNameHurigana,
    sex: content.sex,
    postCode: content.postCode,
    address01: content.address01,
    address02: content.address02,
    userHousePhone: content.userHousePhone,
    userPhone: content.userPhone,
    userFaxNumber: content.userFaxNumber,
    userMemo: content.userMemo,
    emergencyContactRelation: content.emergencyContactRelation,
    emergencyContactName: content.emergencyContactName,
    emergencyContactAddress: content.emergencyContactAddress,
    emergencyContactPhone: content.emergencyContactPhone,
    belongTo: content.belongTo,
    insuranceType: content.insuranceType,
    workerType: content.workerType,
    workScope: content.workScope,
    enrollmentType: content.enrollmentType,
    userGroup: content.userGroup,
    authority: content.authority,
    userBirth: content.userBirth,
    employmentStartAt: content.employmentStartAt,
    employmentEndAt: content.employmentEndAt,
    employmentType: "-",
    userLastNumber:
      content.userLastNumber === null || content.userLastNumber === ""
        ? "-"
        : content.userLastNumber,
    // add more inputs and groups as needed
  });
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [files, setFiles] = useState([]);
  const [attfiles, setAttFiles] = useState([]);
  const [selectedSubType01, setSelectedSubType01] = useState("-");
  const [selectedSubType02, setSelectedSubType02] = useState("-");
  const [sortColumn, setSortColumn] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("asc");
  const [filteredWorkers, setFilteredWorkers] = useState([]);
  const [selectedcat1, setSelectedCat1] = useState("");
  const [selectedcat2, setSelectedCat2] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [admin, setAdmin] = useState("");
  const [userId, setUserId] = useRecoilState(userIdState);
  const [uniqueGroupIdsState, setUniqueGroupIdsState] = useState([]);
  const [matchingFiles, setMatchingFiles] = useState("");
  const [matchingFiles2, setMatchingFiles2] = useState([]);
  const [csv, setCsvList] = useState(null);
  const handleRowClick = (d) => {
    setSelectedItem(d);
    console.log("selectedItem", selectedItem);
  };
  useEffect(() => {
    if (selectedItem) {
      const filteredFiles = notilist.filter(
        (file) => file.notificationGroupId === selectedItem.notificationGroupId
      );
      //   setMatchingFiles(filteredFiles);

      getSentNotificationList(selectedItem.notificationGroupId).then(
        (result) => {
          setMatchingFiles2(result);
        }
      );
    }
  }, [selectedItem]);
  const handleCsv = async (selectedItem) => {
    const CsvList = await getSentNotificationList(
      selectedItem.notificationGroupId
    );
    setCsvList(CsvList);
    console.log("csv", CsvList);
    let newList = [];
    for (let i = 0; i < CsvList.length; i++) {
      newList.push({
        お知らせID: CsvList[i].notificationId,
        お知らせグループID: CsvList[i].notificationGroupId,
        カテゴリー1: CsvList[i].subType01,
        カテゴリー2: CsvList[i].subType02,
        お知らせタイトル: CsvList[i].title,
        お知らせ本文: CsvList[i].pushMessage,
        添付ファイル: CsvList[i].fileContent,
        送信日時: CsvList[i].createdAt,
        送信者: CsvList[i].sentUserGrade,
        既読:
          CsvList[i].isRead.trim().toUpperCase() === "READ" ? "既読" : "未読",
        id: CsvList[i].id,
        メールアドレス: CsvList[i].userEmail,
        // ログインID: CsvList[i].userUniqueId,
        // パスワード: admin === "管理者A" ? CsvList[i].userPassword : "***",
        ログインID: "***",
        パスワード: "***",
        隊員番号: CsvList[i].userNumber,
        裏番号: CsvList[i].userLastNumber,
        名前: CsvList[i].userName,
        フリガナ: CsvList[i].userNameHurigana,
        性別: CsvList[i].sex,
        // 生年月日: CsvList[i].userBirth,
        // 郵便番号: CsvList[i].postCode,
        // 住所01: CsvList[i].address01,
        // 住所02: CsvList[i].address02,
        // 住所03: CsvList[i].address03,
        // 電話番号: CsvList[i].userHousePhone,
        // 携帯電話: CsvList[i].userPhone,
        // FAX番号: CsvList[i].userFaxNumber,
        生年月日: "***",
        郵便番号: "***",
        住所01: "***",
        住所02: "***",
        住所03: "***",
        電話番号: "***",
        携帯電話: "***",
        FAX番号: "***",
        所属: CsvList[i].belongTo,
        保険区分: CsvList[i].insuranceType,
        社員区分: CsvList[i].workerType,
        業務区分: CsvList[i].workScope,
        在籍区分: CsvList[i].enrollmentType,
        グループ: CsvList[i].userGroup,
        雇用期間_始: CsvList[i].employmentStartAt,
        雇用期間_終: CsvList[i].employmentEndAt,
        // 緊急連絡先関係: CsvList[i].emergencyContactRelation,
        // 緊急連絡先名前: CsvList[i].emergencyContactName,
        // 緊急連絡先住所: CsvList[i].emergencyContactAddress,
        // 緊急連絡先電話番号: CsvList[i].emergencyContactPhone,
        緊急連絡先関係: "***",
        緊急連絡先名前: "***",
        緊急連絡先住所: "***",
        緊急連絡先電話番号: "***",
        権限: CsvList[i].authority,
        メモ: CsvList[i].userMemo,
      });
    }
    downloadExcel(newList);
    console.log("csv2", newList);
  };
  const downloadExcel = (csv) => {
    const worksheet = XLSX.utils.json_to_sheet(csv);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "DataSheet.xlsx");
  };
  const renderOtherElements = () => {
    const matchingFiles = notilist.filter(
      (file) => file.notificationGroupId === selectedItem.notificationGroupId
    );
    return (
      <>
        {selectedItem && (
          <Card className=" border">
            <Card.Header>
              <div>
                <h2>{selectedItem.title}</h2>

                <Badge variant="dark light mr-2 rounded-lg">
                  {selectedItem.subType01}
                </Badge>
                <Badge variant="dark light rounded-lg">
                  {selectedItem.subType02}
                </Badge>
              </div>
              <i
                className="fa fa-solid fa-close font-18 mr-3 "
                onClick={() => setSelectedItem("")}></i>
            </Card.Header>
            <Card.Body>
              <div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: selectedItem.content.replace(/\r\n/g, "<br>"),
                  }}></p>
              </div>
              <div>
                <Button
                  className="btn btn-primary rounded btn-sm"
                  onClick={() => handleCsv(selectedItem)}>
                  履歴詳細ダウンロード
                </Button>
              </div>
            </Card.Body>
            {selectedItem.fileList &&
            matchingFiles.length > 0 &&
            (selectedItem.tags === "勤務明細" ||
              selectedItem.tags === "給与明細") ? (
              <>
                <Card.Footer>
                  <div className="form-group mb-2 mx-auto">
                    <p className="small">添付ファイル</p>

                    {selectedItem.fileList.length > 0 && (
                      <>
                        {" "}
                        <iframe
                          src={`https://docs.google.com/viewer?url=${selectedItem.fileList[0].fileUrl}&embedded=true`}
                          title="viewer0"
                          width={600}
                          height={600}></iframe>
                        <br></br>
                        <a
                          href={selectedItem.fileList[0].fileUrl}
                          target="_blank">
                          <Button variant="dark light btn-sm">
                            {selectedItem.fileList[0].fileId
                              ? selectedItem.fileList[0].fileName
                              : selectedItem.fileList[0].name}
                          </Button>
                        </a>
                      </>
                    )}
                  </div>
                </Card.Footer>
              </>
            ) : matchingFiles.length > 0 ? (
              <>
                <Card.Footer>
                  <div className="form-group mb-2 mx-auto">
                    <p className="small">添付ファイル</p>

                    {/* <DocViewer
                      prefetchMethod="GET"
                      pluginRenderers={DocViewerRenderers}
                      documents={docs}
                    /> */}
                    {selectedItem.fileList.map((d, i) => {
                      const url = `https://docs.google.com/viewer?url=${d.fileUrl}&embedded=true`;

                      // 파일 확장자 추출
                      let extension = "";
                      if (d.fileUrl) {
                        const url = `https://docs.google.com/viewer?url=${d.fileUrl}&embedded=true`;
                        extension = d.fileUrl.split(".").pop().toLowerCase();
                      }

                      // 이미지 확장자 배열
                      const imageExtensions = ["jpg", "jpeg", "png", "gif"];

                      return (
                        <div key={i} className="mb-3">
                          {/* 이미지 파일일 경우 */}

                          {imageExtensions.includes(extension) ? (
                            <img
                              src={d.fileUrl}
                              width="300"
                              style={{ margin: "2px" }}
                              alt=""
                            />
                          ) : (
                            /* 이미지 파일 이외의 파일일 경우 */
                            <iframe
                              src={url}
                              title={`viewer${i}`}
                              width={600}
                              height={600}></iframe>
                          )}
                          <br></br>
                          <a href={d.fileUrl} target="_blank" rel="noreferrer">
                            <Button variant="dark light btn-sm">
                              {d.fileId ? d.fileName : d.name}
                            </Button>
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </Card.Footer>
              </>
            ) : null}
          </Card>
        )}
      </>
    );
  };

  useEffect(() => {
    getLocalStorage("admininfo")
      .then((admin) => {
        setAdmin(admin);
      })
      .catch((error) => {
        console.error("에러 발생:", error);
      });
    init();
  }, []);

  const init = async () => {
    const [userid2, catList, filteredData] = await Promise.all([
      getLocalStorage("uuN"),
      getCategoryService(),
      getSentNotification(await getLocalStorage("uuN")),
    ]);

    setNotiList(filteredData);

    const activeCatList = catList.filter((cat) => cat.status !== "INACTIVE");
    setCateList(activeCatList);
    setTags((prevState) =>
      prevState.map((tags, index) => {
        const filteredCats = activeCatList.filter(
          (cat) => cat.categoryBigClassName === String(index)
        );
        return filteredCats.map((cat) => ({
          name: cat.categorySmallClassName,
          id: cat.id,
          status: cat.status,
        }));
      })
    );

    setFilteredWorkers(
      filteredData.filter((d, i, arr) => {
        // Check if the current index is the first occurrence of this notificationGroupId in the array
        const isFirstOccurrence =
          arr.findIndex(
            (x) => x.notificationGroupId === d.notificationGroupId
          ) === i;
        return isFirstOccurrence;
      })
    );
  };
  const handleSort = (column) => {
    // If the same column is clicked again, reverse the sorting direction
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      setCurrentPage(0);
    } else {
      setSortColumn(column);
      setSortDirection("asc");
      setCurrentPage(0);
    }
  };
  const sortedWorkers = useMemo(() => {
    let sorted = [...filteredWorkers];

    if (sortColumn) {
      sorted = sorted.sort((a, b) => {
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];

        if (sortColumn === "createdAt") {
          // Sort by createdAt in descending order
          return new Date(valueB) - new Date(valueA);
        }

        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
      });

      if (sortDirection === "asc") {
        sorted = sorted.reverse();
      }
    }

    return sorted;
  }, [filteredWorkers, sortColumn, sortDirection]);
  const itemsPerPage = 12000;
  const totalPages = Math.ceil(sortedWorkers.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = sortedWorkers.slice(startIndex, endIndex);
  const optimizedFilteredItems = useMemo(() => {
    if (admin !== "管理者A") {
      return currentItems.filter(
        (item) => item.subType01 !== "勤務明細" && item.subType01 !== "給与明細"
      );
    }
    return currentItems;
  }, [currentItems, admin]); // admin과 currentItems 상태가 변경될 때만 재계산

  const uniqueNotificationGroupIds = useMemo(() => {
    return Array.from(
      new Set(optimizedFilteredItems.map((d) => d.notificationGroupId))
    );
  }, [optimizedFilteredItems]); // optimizedFilteredItems가 변경될 때만 재계산

  const renderTableRows = () => {
    return uniqueNotificationGroupIds.map((groupId) => {
      const matchingItem = optimizedFilteredItems.find(
        (item) => item.notificationGroupId === groupId
      );
      return (
        <tr
          key={matchingItem.notificationGroupId}
          onClick={() => handleRowClick(matchingItem)}>
          <td className="text-left width200">{matchingItem.subType01}</td>
          <td className="text-left width200">{matchingItem.subType02}</td>
          <td className="text-left text-primary">{matchingItem.title}</td>
          <td className="text-left width140 text-center">
            {matchingItem.realCount}
          </td>
          <td className="text-left width200">
            <b>{matchingItem.sentUserGrade}</b> - {matchingItem.sentUserName}
          </td>
          <td className="width200 text-center">
            {format(new Date(matchingItem.createdAt), "yyyy/MM/dd HH:mm")}
          </td>
        </tr>
      );
    });
  };

  // useEffect(() => {
  //   if (indefiniteEnd) {
  //     setFormValues((prev) => ({ ...prev, employmentEndAt: "99991231" }));
  //   }
  // }, [indefiniteEnd]);

  return (
    <>
      <PageTitle activeMenu="通知履歴" motherMenu="社員情報" />
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-body px-3">
            <div id="sent">
              {/* <div className="px-3 py-4 d-flex align-items-center justify-content-start">
                    <div className="flex-row d-flex align-items-center justify-content-start">
                      <i className="fa fa-solid fa-chevron-left font-18 align-middle mx-auto"></i>
                      <h2 className="font-weight-bold mx-4">2022年</h2>
                      <i className="fa fa-solid fa-chevron-right font-18 align-middle mx-auto"></i>
                    </div>
                  </div> */}
              <div>
                {/* <div className="form-head d-flex mb-3 align-items-center mt-4">
                      <select
                        value={selectedcat1}
                        onChange={(e) => setSelectedCat1(e.target.value)}
                        className={`form-control col-02 mr-3 ${
                          selectedcat1 === "" ? "default-option" : ""
                        }`}>
                        <option value="">カテゴリー1</option>
                        {catlist
                          .filter((cat) => cat.categoryBigClassName === "0") // filter by categoryBigClassName
                          .map((cat) => (
                            <option
                              key={cat.id}
                              value={cat.categorySmallClassName}>
                              {cat.categorySmallClassName}
                            </option>
                          ))}
                      </select>

                      <select
                        value={selectedcat2}
                        onChange={(e) => setSelectedCat2(e.target.value)}
                        className={`form-control col-02 mr-3 ${
                          selectedcat2 === "" ? "default-option" : ""
                        }`}>
                        <option value="">カテゴリー2</option>
                        {catlist
                          .filter((cat) => cat.categoryBigClassName === "1") // filter by categoryBigClassName
                          .map((cat) => (
                            <option
                              key={cat.id}
                              value={cat.categorySmallClassName}>
                              {cat.categorySmallClassName}
                            </option>
                          ))}
                      </select>

                      <div className="input-group search-area rounded d-inline-flex ml-auto ">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="検索"
                          value={query}
                          onChange={(e) => setQuery(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleFilterAndSearch();
                            }
                          }}
                        />
                        <div
                          className="input-group-append"
                          onClick={handleFilterAndSearch}>
                          <span className="input-group-text">
                            <i className="flaticon-381-search-2" />
                          </span>
                        </div>
                      </div>
                    </div> */}
                <div className="tableFixHead">
                  <Table responsive hover>
                    <thead className="thead-info">
                      <tr>
                        <th
                          className="text-left width200"
                          onClick={() => handleSort("category")}>
                          カテゴリー1
                        </th>
                        <th
                          className="text-left width200"
                          onClick={() => handleSort("type")}>
                          カテゴリー2
                        </th>
                        <th
                          className="text-left"
                          onClick={() => handleSort("title")}>
                          件名
                        </th>
                        <th className="text-left width140 text-center">
                          送信人数
                        </th>
                        <th className="text-left width200 text-center">
                          送信者
                        </th>
                        <th
                          onClick={() => handleSort("createdAt")}
                          className="text-center">
                          送信日時
                        </th>
                      </tr>
                    </thead>
                    <tbody>{renderTableRows()}</tbody>
                  </Table>
                </div>
                {renderOtherElements()}

                <div className="dataTables_wrapper">
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                    <div className="dataTables_info">
                      {startIndex + 1} ～{" "}
                      {Math.min(endIndex, sortedWorkers.length)} 表示 （総
                      {sortedWorkers.length} 件）
                    </div>
                    <div className="dataTables_paginate paging_simple_numbers">
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="example5_paginate">
                        <Link
                          className={`paginate_button previous ${
                            currentPage === 0 ? "disabled" : ""
                          }`}
                          onClick={() => {
                            if (currentPage === 0) {
                              return;
                            }
                            setCurrentPage(currentPage - 1);
                          }}>
                          戻る
                        </Link>

                        {/* {Array.from({ length: totalPages }).map((_, i) => (
                        <Link
                          key={i}
                          className={`paginate_button ${
                            currentPage === i ? "current" : ""
                          }`}
                          onClick={() => setCurrentPage(i)}>
                          {i + 1}
                        </Link>
                      ))} */}

                        <Link
                          className={`paginate_button next ${
                            currentPage === totalPages - 1 ? "disabled" : ""
                          }`}
                          onClick={() => {
                            if (currentPage === totalPages - 1) {
                              return;
                            }
                            setCurrentPage(currentPage + 1);
                          }}>
                          次へ
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SentNoti;
